import request from '@/utils/request'

export function getNeighborhoodById(data) {
  return request({
    url: `/api/Neighborhood/GetNeighborhoodById/${data}`,
    method: 'get',
  })
}

export function getNeighborhoodsByTownId(data) {
  return request({
    url: `/api/Neighborhood/GetNeighborhoodsByTownId/${data}`,
    method: 'get',
  })
}

export function GetNeighborhoodsByTownIdNeighbourhoodName(townId, neighborhoodName) {
  return request({
    url: `/api/Neighborhood/GetNeighborhoodsByTownIdNeighbourhoodName/${townId}/${neighborhoodName}`,
    method: 'get',
  })
}

export function getNeighborhoods() {
  return request({
    url: '/api/Neighborhood/GetNeighborhoods',
    method: 'get',
  })
}

export function addNeighborhood(data) {
  return request({
    url: '/api/Neighborhood/AddNeighborhood',
    method: 'post',
    data,
  })
}

export function updateNeighborhood(data) {
  return request({
    url: '/api/Neighborhood/UpdateNeighborhood',
    method: 'put',
    data,
  })
}
export function deleteNeighborhood(data) {
  return request({
    url: `/api/Neighborhood/DeleteNeighborhood?neighborhoodId=${data}`,
    method: 'delete',
  })
}
