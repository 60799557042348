import request from '@/utils/request'

// eslint-disable-next-line import/prefer-default-export
export function GetTownById(data) {
  return request({
    url: `/api/Town/GetTownById/${data}`,
    method: 'get',
  })
}

export function GetTownsByCityId(data) {
  return request({
    url: `/api/Town/GetTownsByCityId/${data}`,
    method: 'get',
  })
}

export function GetTownByTownName(data) {
  return request({
    url: `/api/Town/GetTownByTownName/${data}`,
    method: 'get',
  })
}

export function AddTown(data) {
  return request({
    url: '/api/Town/AddTown',
    method: 'post',
    data,
  })
}

export function UpdateTown(data) {
  return request({
    url: '/api/Town/UpdateTown',
    method: 'put',
    data,
  })
}

export function DeleteTown(data) {
  return request({
    url: `/api/Town/DeleteTown?TownId=${data}`,
    method: 'delete',
  })
}
